<template>
  <div class="catalog" :class="{ 'loading': isItemsLoading }">
    <div v-if="breadcrumbs" class="breadcrumbs">
      <router-link
        v-for="item of breadcrumbs"
        :key="item.title"
        :to="item.link"
        class="breadcrumbs-item text-overflow"
      >
        {{ item.title }}
      </router-link>
    </div>
    <component
      :is="title.backLink ? 'router-link' : 'div'"
      v-if="title.label || title.backLink"
      :to="title.backLink"
      class="title"
    >
      <i v-if="title.backLink" class="fas fa-chevron-left"/>
      <span class="text-overflow">{{ title.label }}</span>
    </component>
    <nav class="navigation">
      <router-link
        v-for="item of navigation"
        :key="item.id"
        :to="item.link"
        :active-class="'active'"
      >
        {{ item.title }}
        <i class="fas fa-chevron-right"/>
      </router-link>
    </nav>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'CatalogNav',
    computed: {
      ...mapGetters({
        isItemsLoading: 'catalogProducts/isItemsLoading',
        breadcrumbs: 'catalogProducts/breadcrumbs',
        title: 'catalogProducts/title',
        navigation: 'catalogProducts/navigation',
      }),
    },
  }
</script>
<style lang="scss" scoped>
  .catalog {
    position: relative;
    height: 100%;
    padding: 30px 20px 30px 35px;
    background-color: $leftSideBar-background-color;

    &.loading {
      & > * {
        filter: blur(1px);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        //background-color: rgba(#000, 0.08);
      }
    }
  }
  
  .breadcrumbs {
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 14px;
  }

  .breadcrumbs-item {
    position: relative;
    display: inline-block;
    padding-right: 8px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: $main-text-color;

    &::before {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 8px;
      display: block;
      height: 1px;
      background-color: rgba($main-text-color, 0.1);
    }

    &::after {
      content: '/';
      position: absolute;
      top: 0;
      right: 2px;
      display: block;
    }

    &:last-child {
      padding-right: 0;

      &::before {
        right: 0;
      }

      &::after {
        content: none;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
    color: $main-text-color;

    i {
      display: inline-block;
      width: 8px;
      margin-right: 15px;
    }
  }

  .navigation {
    display: block;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 15px;
      line-height: 32px;
      color: $main-text-color;

      i {
        display: none;
        font-weight: 400;
      }

      &:hover {
        color: $main-color;
      }

      &.active {
        font-weight: 500;
        color: $main-color;

        i {
          display: block;
        }
      }
    }
  }
</style>
