<template>
  <div class="page">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <div class="page-container">
      <div class="page-catalog">
        <catalog-nav/>
      </div>
      <div class="page-content">
        <catalog-content/>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  import CatalogNav from './CatalogNav/CatalogNav';
  import CatalogContent from './CatalogContent/CatalogContent';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'CatalogPage',
    components: {
      CatalogNav,
      CatalogContent,
    },
    data() {
      return {
        metadata: {},
      }
    },
    computed: {
      ...mapGetters({
        head: 'catalogProducts/head',
      }),
    },
    watch: {
      head: function(head) {
        const metadata = normaliseMetaTags(head);
        const isCatalogOfZeroLevel = this.$route.path.match(/^\/catalog$/);
        if (isCatalogOfZeroLevel) {
          metadata.title = 'Catalog';
        }
        this.metadata = metadata;
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.toggleLoading(true);
        vm.getCatalogConfig({ url: to.params.pathMatch, query: to.query?.sort });
      });
    },
    beforeRouteUpdate(to, from, next) {
      this.toggleLoading(true);
      this.getCatalogConfig({ url: to.params.pathMatch, query: to.query?.sort });
      next();
    },
    created() {
      $stats.send('page_opened', {
        page_type: 'catalog',
      });
    },
    methods: {
      ...mapActions({
        getCatalogConfig: 'catalogProducts/getCatalogConfig',
        toggleLoading: 'catalogProducts/toggleLoading',
      }),
    },
  };
</script>
<style lang="scss" scoped>
  .page {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1920px;
    min-height: calc(100vh - 72px);
    margin: auto;
    padding: 20px;

    @media (max-width: $md-breakpoint) {
      min-height: calc(100vh - 55px);
    }

    .spinner-border {
      position: absolute;
      top: calc(50% - 23px);
      left: calc(50% - 23px);
    }
  }

  .page-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: 100%;
  }

  .page-catalog {
    display: none;
    flex-basis: 349px;
    max-width: 349px;

    @media (min-width: 960px) {
      display: block;
    }
  }

  .page-content {
    flex: 1;
    padding: 10px 20px 20px;
    margin-left: -20px;
    margin-right: -20px;
    overflow: hidden;

    @media (min-width: 960px) {
      flex-basis: calc(100% - 349px);
      max-width: calc(100% - 349px);
      margin: 0;
      padding: 10px 40px 40px;
    }
  }

</style>
