<template>
  <div class="catalog-content">
    <b-spinner v-if="isItemsLoading"/>
    <template v-else>
      <component
        :is="title.backLink ? 'router-link' : 'div'"
        v-if="title.label || title.backLink"
        :to="title.backLink"
        :class="{ 'title-products': isLastLevel }"
        class="title"
      >
        <i v-if="title.backLink" class="fas fa-chevron-left"/>
        <span class="text-overflow">{{ title.label }}</span>
      </component>
      <!-- Carousels -->
      <div v-if="!isLastLevel" class="carousels">
        <section
          v-for="(carousel, index) of carousels"
          :key="`${carousel.title}_${index}`"
          class="carousel-item"
        >
          <template v-if="carousel.data.length">
            <div class="carousel-header">
              <h2 class="carousel-header__title">
                {{ carousel.title }}
              </h2>
              <router-link v-if="carousel.link" :to="carousel.link" class="carousel-header__link">
                View all
              </router-link>
            </div>
            <swiper-carousel
              :items="carousel.data"
              :items-type="'vertical'"
              :config="carouselConfig"
              :link="carousel.link"
            />
          </template>
        </section>
      </div>
      <!-- Products -->
      <div v-if="isLastLevel" class="products">
        <div class="products-header">
          <h2 class="products-title">
            {{ title.label }}
          </h2>

          <products-sort-select
            :sort-options="productSortItems"
            @selectHandler="sortSelect($event)"
          />
        </div>
        <div class="products-container">
          <carousel-item
            v-for="(product, index) of products"
            :key="`${product.id}_${index}`"
            :item="product"
            :item-type="'vertical'"
          />
        </div>
        <div
          v-if="canLoadMoreProducts && !productsLoading"
          v-b-visible="getProducts"
        />
      </div>
    </template>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';

  import { VERTICAL_CAROUSEL_ITEMS_CONFIG } from 'src/config/carousel.config';
  import SwiperCarousel from 'src/components/swiper-carousel/swiper-carousel';
  import CarouselItem from 'src/components/swiper-carousel/carousel-item/carousel-item';
  import ProductsSortSelect from 'src/components/products-sort-select/products-sort-select';
  import { productSortItems } from 'src/config/product-sort.config';

  export default {
    name: 'CatalogContent',
    components: {
      SwiperCarousel,
      CarouselItem,
      ProductsSortSelect,
    },
    data() {
      return {
        productSortItems: productSortItems,
        carouselConfig: {
          ...VERTICAL_CAROUSEL_ITEMS_CONFIG,
          breakpoints: {
            1760: { slidesPerView: 8 },
            1600: { slidesPerView: 7, spaceBetween: 32},
            1440: { slidesPerView: 6, spaceBetween: 32},
            1280: { slidesPerView: 5, spaceBetween: 32 },
            1120: { slidesPerView: 4, spaceBetween: 32},
            1024: { slidesPerView: 4, spaceBetween: 32},
            1020: { slidesPerView: 4, spaceBetween: 12},
            960: { slidesPerView: 3 },
            800: { slidesPerView: 5 },
            640: { slidesPerView: 4 },
            480: { slidesPerView: 3 },
            300: { slidesPerView: 2 },
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        title: 'catalogProducts/title',
        isLastLevel: 'catalogProducts/isLastLevel',
        carousels: 'catalogProducts/carousels',
        itemsLink: 'catalogProducts/itemsLink',
        isItemsLoading: 'catalogProducts/isItemsLoading',
        productsLoading: 'catalogProducts/productsLoading',
        products: 'catalogProducts/products',
        canLoadMoreProducts: 'catalogProducts/canLoadMoreProducts',
      }),
    },
    watch: {
      '$route': {
        immediate: true,
        handler(link) {
          const param = link.params.pathMatch.split('/')
            .filter(item => item)
            .pop() === 'view-all' ? true : false;
          this.$store.commit('catalogProducts/setIsAllProductSortLvl', param)
        },
      },
    },
    methods: {
      ...mapActions({
        getProductItems: 'catalogProducts/getProductItems',
        toggleProductsLoading: 'catalogProducts/toggleProductsLoading',
      }),
      getProducts(visible) {
        if (this.canLoadMoreProducts && visible) {
          this.getProductItems();
        }
      },
      sortSelect(item) {
        this.$router.push({ query: item ? { sort: item } : null });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .catalog-content {
    position: relative;
    min-height: 100%;

    .spinner-border {
      position: absolute;
      top: calc(50% - 23px);
      left: calc(50% - 23px);
    }
  }

  .title {
    display: none;
    align-items: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
    color: $main-text-color;

    @media (max-width: 959px) {
      display: flex;
    }

    i {
      display: inline-block;
      width: 8px;
      margin-right: 15px;
    }
  }

  .carousel-item {
    position: relative;
    margin-bottom: 30px;
  }

  .carousel-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &__title {
      font-size: 22px;
      font-weight: 500;
      color: $main-text-color;
    }

    &__link {
      font-size: 14px;
      color: $main-color;
    }
  }

  .products-title {
    display: none;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;

    @media (min-width: 960px) {
      display: block;
    }
  }

  .products-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(159px, max-content));
    gap: 15px;
  }
  .products-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .products-actions {
    &::v-deep {
      .dropdown-toggle::after {
        content: none;
      }
      .btn-group {
        display: flex;
        justify-content: space-between;
        @media (max-width: 959px) {
          width: 335px;
          flex-basis: 335px;
        }
      }
      .btn-secondary {
        display: flex;
        appearance: none;
        background: url('../../../assets/icons/icon-sortArow.svg') no-repeat right
          0.75rem center/8px 10px;
        border-color: #e1e1f1;
        background-color: #fff;
        font-size: 15px;
        color: #333953;
        max-width: 139px;
        @media (max-width: 959px) {
          max-width: 100%;
        }
        &:active{
          background-color: #fff;
          color: #333953;
          box-shadow: none;
          outline: none;
          border-color: #e1e1f1;
        }
        &:focus{
          box-shadow: none;
          outline: none;
          border-color: #e1e1f1;
        }
        &:active:focus{
          box-shadow: none;
        }
      }
      .dropdown-item {
        &:active{
          background: none;
        }
        @media (max-width: 554px) {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .dropdown-menu {
        border-color: #e1e1f1;
        outline: none;
        @media (max-width: 554px) {
          max-width: 335px;
        }
      }
    }
  }

  .products-sort {
    display: flex;
    align-items: center;
    .products-sort-title {
      @media (max-width: 554px) {
        display: none;
      }
    }
    span {
      margin-right: 10px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>